import React, { Component } from 'react';

class Resume extends Component {
  render() {
    return (
        <main id="main">
            <section id="resume" class="resume">
                <div class="container">

                    <div class="section-title">
                        <h2>Resume</h2>
                        <p>Quick Resume about my Education level and Work Experience</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6" data-aos="fade-up">
                            <h3 class="resume-title">Sumary</h3>
                            <div class="resume-item pb-0">
                                <h4>Kin Hoe</h4>
                                <p>
                                    <em>
                                        Creative and Initiative Cloud/DevOps Engineer with close to 2 years of experience 
                                        in cloud and devops tools.
                                        Support and deliver user request on cloud or networking related issues.
                                    </em>
                                </p>
                                <ul>
                                    <li>yapkinhoe1@gmail.com</li>
                                </ul>
                            </div>

                            <h3 class="resume-title">Education</h3>
                            <div class="resume-item">
                                <h4>Bachelor Degree in Science &amp; Information Security</h4>
                                <h5>2015 - 2016</h5>
                                <p><em>Tunkun Abdul Rahman University of Management and Technology, Kuala Lumpur, MY</em></p>
                                <p>study fundamental of cybersecurity, networking, programmings, and application development</p>
                            </div>
                            <h3 class="resume-title">Skills/Tools</h3>
                            <div class="resume-item">
                                <h4>All the tools/skillset came across to my experience</h4>
                                <p><em>Python scripting</em></p>
                                <p><em>Bash scripting</em></p>
                                <p><em>Jenkins scripting(CI/CD)</em></p>
                                <p><em>Azure DevOps pipelines</em></p>
                                <p><em>PgAdmin 4</em></p>
                                <p><em>MsSQL Studio</em></p>
                                <p><em>Linux</em></p>
                                <p><em>Windows</em></p>
                                <p><em>Vulnerability Patching/Security Implementation</em></p>
                                <p><em>Gitlab</em></p>
                                <p><em>Github</em></p>
                                <p><em>Git</em></p>
                                <p><em>AWS Cloud serverless architecture(serve s3 static website, distribute using cloudfront)</em></p>
                                <p><em>AWS Cloud Services</em></p>
                                <p><em>Azure Cloud Services</em></p>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="resume-title">Professional Experience</h3>
                            <div class="resume-item">
                                <h4>DevOps Engineer</h4>
                                <h5>2023 JUL - Present</h5>
                                <p><em>Fourtitude.asia, Kuala Lumpur, MY </em></p>
                                <ul>
                                    <li>Involved in designing architecture for Azure Cloud Project with Azure Kubernetes</li>
                                    <li>Provisioning Azure Services such as virtual networks, virtual machines as part of project requirements</li>
                                    <li>Involved in Azure Kubernetes setup and configuration</li>
                                    <li>Create ad-hoc solution to have temporary hub network for the requirement of having centralized Nat Gateway</li>
                                </ul>
                            </div>
                            <div class="resume-item">
                                <h4>DevOps Engineer</h4>
                                <h5>2023 JUN - 2023 JUL</h5>
                                <p><em>Fourtitude.asia, Kuala Lumpur, MY </em></p>
                                <ul>
                                    <li>Explore on Azure Cloud Services</li>
                                    <li>Internal testing on Azure Networking:(Hub and Spoke Network)</li>
                                    <li>Internal testing on Azure DevOps to deploy code into Azure Kubernetes</li>
                                    <li>Internal testing on Azure Kubernetes</li>
                                </ul>
                            </div>
                            <div class="resume-item">
                                <h4>DevOps Engineer</h4>
                                <h5>2023 JAN - 2023 MAY</h5>
                                <p><em>Fourtitude.asia, Kuala Lumpur, MY </em></p>
                                <ul>
                                    <li>Assist closely in CI/CD design and setup for aws code pipelines</li>
                                    <li>Involved in aws environment setup, such as vpc, and subnets</li>
                                    <li>Elastic container services and elastic registry setup and work closely with devloper for deployments</li>
                                    <li>Create serverless monitoring architecture using aws lambda, cloudwatch, and s3 for monitoring and send alert to telegram</li>
                                    <li>Python scripting for analyze, parse, and patch postgresql databse</li>
                                </ul>
                            </div>
                            <div class="resume-item">
                                <h4>Junior Devops Engineer</h4>
                                <h5>2021 AUG - 2023 JAN</h5>
                                <p><em>Fourtitude.asia, Kuala Lumpur, MY</em></p>
                                <ul>
                                    <li>Cloud engineer support in setup cloud environment</li>
                                    <li>Involved with direct connect setup, network troubleshoting</li>
                                    <li>Python scripting for serverless, create lambda</li>
                                    <li>provision resources such as virtual network and app service for azure environment</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    );
  }
}
 
export default Resume;
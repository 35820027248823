import React, { Component } from 'react';
import Typed from 'typed.js';

class Facts extends Component {
    constructor(props) {
        super(props);
        this.state = { progress: 55 };
      }
    
  render() {
    return (
        <main id="main">
    <section id="skills" class="skills section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Skills</h2>
        </div>

        <div class="row skills-content">

          <div class="col-lg-6" data-aos="fade-up">

            <div class="progress">
              <span class="skill">AWS Cloud <i class="val">80%</i></span>
              <div class="progress-bar-wrap">
              <div className="progress-bar" role="progressbar" style={{ width: `80%` }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">AWS Severless <i class="val">80%</i></span>
              <div class="progress-bar-wrap">
              <div className="progress-bar" role="progressbar" style={{ width: `80%` }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div className="progress">
                <span className="skill">Python <i className="val">90%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" style={{ width: `90%` }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
      </div>
          </div>

          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
          <div class="progress">
              <span class="skill">AWS Devops Tools <i class="val">70%</i></span>
              <div class="progress-bar-wrap">
              <div className="progress-bar" role="progressbar" style={{ width: `70%` }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Jenkins <i class="val">70%</i></span>
              <div class="progress-bar-wrap">
              <div className="progress-bar" role="progressbar" style={{ width: `70%` }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          <div class="progress">
              <span class="skill">Azure Cloud <i class="val">80%</i></span>
              <div class="progress-bar-wrap">
              <div className="progress-bar" role="progressbar" style={{ width: `80%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Azure Devops <i class="val">50%</i></span>
              <div class="progress-bar-wrap">
              <div className="progress-bar" role="progressbar" style={{ width: `50%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            {/* <div class="progress">
              <span class="skill">WordPress/CMS <i class="val">90%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div> */}

            {/* <div class="progress">
              <span class="skill">Photoshop <i class="val">55%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div> */}

          </div>

        </div>

      </div>
    </section>
        </main>

    );
  }
}
 
export default Facts;
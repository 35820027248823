import React, { Component } from 'react';
import Typed from 'typed.js';

class About extends Component {
  render() {
    return (
        <main id="main">
        <section id="about" class="about">
          <div class="container">
    
            <div class="section-title">
              <h2>About</h2>
              <p>
                Graduated from TARUC, majored in Information Security.
                Currently working as a Cloud Engineer/Devops Engineer.
                My scope of interest does not limit to cloud and devops, but also includes scripting python, ci/cd, automation stuff.
                I am python lover.

                This website built with ReactJS,hosted on AWS S3 behind Cloudfront.
              </p>
            </div>
    
            <div class="row">
              <div class="col-lg-4" data-aos="fade-right">
                <img src="assets/img/profile-img-2.jpg" class="img-fluid" alt=""/>
              </div>
              <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                <h3>Cloud Engineer &amp; Devops Engineer.</h3>
                <p class="fst-italic">
                  Below are some details about me
                </p>
                <div class="row">
                  <div class="col-lg-6">
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>04 April</span></li>
                        <li>
                        <i class="bi bi-chevron-right"></i> <strong>Website:</strong> 
                        <span>https://porotomato.devopsvlog.com</span>
                        </li>
                        <li>
                        <i class="bi bi-chevron-right"></i> <strong>Website:</strong> 
                        <span>https://www.devopsvlog.com</span>
                        </li>
                      {/* <li><i class="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+123 456 7890</span></li> */}
                      <li><i class="bi bi-chevron-right"></i> <strong>City:</strong> <span>Kuala Lumpur, Malaysia</span></li>
                    </ul>
                  </div>
                  <div class="col-lg-6">
                    <ul>
                      <li><i class="bi bi-chevron-right"></i> <strong>Age:</strong> <span>secret</span></li>
                      <li><i class="bi bi-chevron-right"></i> <strong>Education:</strong> <span>Degree</span></li>
                      {/* <li><i class="bi bi-chevron-right"></i> <strong>PhEmailone:</strong> <span></span></li>
                      <li><i class="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </main>

    );
  }
}
 
export default About;